import { actions } from "astro:actions"
import clsx from "clsx"
import { useRef, useState } from "preact/hooks"
import type { JSX } from "preact/jsx-runtime"
import { button } from "../styles/button"

export const NewsletterSignup = () => {
	const [state, setState] = useState({ pending: false, submitted: false })
	const rForm = useRef<HTMLFormElement>(null)

	async function onSubmit(e: JSX.TargetedEvent<HTMLFormElement>) {
		e.preventDefault()

		const form = rForm.current
		if (!form) return

		setState({ pending: true, submitted: false })

		const data = new FormData(form)
		const result = await actions.newsletter(data)

		if (result.error) {
			alert("Something went wrong. Please try again.")
			return setState({ pending: false, submitted: false })
		}

		setState({ pending: false, submitted: true })
	}

	return (
		<section class="relative -mx-5 mt-16 bg-blue px-5 py-[22px] md:mt-20 lg:-mx-8 lg:mt-24 lg:px-8">
			<div class="mx-auto flex w-full max-w-7xl flex-wrap items-center justify-between gap-x-10 gap-y-5">
				<h2 class="font-serif text-lg text-cream md:text-xl lg:text-2xl">
					{state.submitted
						? "Thank you for subscribing!"
						: "Stay in the loop on all things Koast."}
				</h2>

				<form
					class={clsx(
						"flex grow gap-2 transition-all duration-200 ease-out md:gap-5 lg:justify-end",
						state.submitted && "pointer-events-none invisible opacity-0",
					)}
					ref={rForm}
					onSubmit={onSubmit}
				>
					<div class="grow lg:max-w-[31rem]">
						<input
							type="email"
							name="email"
							placeholder="Email Address"
							class="w-full rounded-lg bg-white px-4 py-3 text-base text-black placeholder:text-black/50 focus-visible:outline-white"
							readOnly={state.pending}
							required
						/>
					</div>

					<button
						class={button({
							variant: "primary",
							size: "small",
							class: "md:min-w-[12.5rem]",
						})}
						disabled={state.pending}
					>
						{state.pending ? "Subscribing..." : "Subscribe"}
					</button>
				</form>
			</div>
		</section>
	)
}
